/* You can add global styles to this file, and also import other style files */

html, body {
	height: 100%;
	width: 100%;
}

app-root {
	display: block;
	height: 100%;
	overflow: scroll;
}

@media print {
	app-root {
		
	}
}
